// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  currentEnv: 'staging',
  // TODO: set this back to HTTP, or implement TLS properly
  server: 'https://dev.api.cartwheel.io/api',
  notificationServer: 'https://dev.notification.cartwheel.io',
  // server: 'http://cartwheelapi.azurewebsites.net'
  // server:'http://localhost:49489'
  heap: {
    appId: '2728066218'
  },
  graphql: {
    wsEndpoint: 'wss://dev.api.cartwheel.io/graphql'
  },
  google: {
    clientId: '920745524482-jevqk6rvp23ue6u332c7b8ij7t32lnsr.apps.googleusercontent.com',
    mapApiKey: 'AIzaSyCft0X3HTT__6CCF2rbND9RK75dM6Oim8k'
  },
  quickbooks: {
    userAuthUrl: 'https://appcenter.intuit.com/connect/oauth2',
    clientId: 'Q0YtVicOjW9i0SUXt6w2PcKNylox2Gm1tN8sV6w5iJTcPxqYtj'
  },
  xero: {
    userAuthUrl: 'https://login.xero.com/identity/connect/authorize',
    clientId: '91113A7511854573A9FEAADA37351546'
  },
  freshbooks: {
    userAuthUrl: 'https://my.freshbooks.com/service/auth/oauth/authorize',
    clientId: '1c72bcbb2b9759dc0b61bd9f738d51c3cd64fb9f229e9688e53dd8231d52db2c'
  },
  chargeBee: {
    site: 'cartwheel-test'
  },
  harvest: {
    userAuthUrl: `https://id.getharvest.com/oauth2/authorize`,
    clientId: 'CVYnf8-oexGADQVAna3gDvEi'
  },
  veem: {
    userAuthUrl: 'https://sandbox-api.veem.com/oauth/authorize',
    clientId: 'cartwheeldev-7Zanjl6aBk'
  },
  toggl: {
    userAuthUrl: 'https://www.toggl.com/api/v8/me'
  },
  adp: {
    userAuthUrl: 'https://accounts.adp.com/auth/oauth/v2/authorize'
  },
  raven: {
    dsn: 'https://1bb8953c03464fb7a40a8a3a6e4d9f2a@sentry.io/301839'
  },
  feature: {
    client: {
      clientImport: false,
      clientExport: false,
      userInvoicing: false
    },
    invoicing: {
      allowUserInvoice: false
    }
  }
};
